// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #362ec4;
$secondary: #8f5325;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;

//$body-bg: #000;
//$body-color: #acacac;

//custom color to be mapped
$accent : #da6d25;

//Bootstrap 4.5.3
$theme-colors: () !default;
$theme-colors: map-merge(
(
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
),
$theme-colors
);
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

